// src/ApolloClient.js
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import React from 'react';

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://atlas-backend.sdm.co/v1/graphql',
        headers: {
            'content-type': 'application/json',
            'x-hasura-admin-secret': 'wqcLAHNtOYVjwYOioQ0L5phUKscbIySsRNcjTxrWnKl'
        }
    }),
    cache: new InMemoryCache(),
});

const ApolloProviderWrapper = ({ children }) => (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
);

export default ApolloProviderWrapper;
